import { Card } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import MintToken from '../MintToken/MintToken';
import { ethSepoliaMATIC, ethSepoliaPOL } from '../../utils/token-claim';

export default function MATIC() {
  return <div>
    <Card>
      <Card.Body>
        <Card.Title>MATIC</Card.Title>
      
        <Tabs
          defaultActiveKey="polygon"
          id="matic"
          className="mb-3"
        >
          <Tab eventKey="polygon" title="POLYGON CHAIN">
            <Card.Text>
              This Faucet requires some real mainnet MATIC in your account <a href="https://www.alchemy.com/faucets/polygon-amoy">Faucet</a>
            </Card.Text>
            <Card.Text>
            This faucet requires discord (also may not work) <a href="https://faucet.polygon.technology/">Faucet</a>
            </Card.Text>
          </Tab>
          <Tab eventKey="ethereum" title="ETHEREUM CHAIN">
            <MintToken currency="MATIC" chain="ETHEREUM" mintHandler={ethSepoliaMATIC} maxAmount={1000000} />
            <MintToken currency="POL" chain="ETHEREUM" mintHandler={ethSepoliaPOL} maxAmount={1000000} />
          </Tab>
        </Tabs>
      </Card.Body>
    </Card>
  </div>
}