import Web3 from 'web3';
import BigNumber from 'bignumber.js';
import abi from './contract-abi.json'

const PRIVATE_KEY = '0x10bc3e3fd1b4d5c845adec9d343f3d801bdc32b4ff0c0d990e789612dcf551d9';

// const PUBKEY = '0xEDC3006aca26C2E7017E0b3644Cc53a25e711765';

function setupWeb3(apiUrl: string, networkId: number) {
  const web3 = new Web3(apiUrl);

  const signer = web3.eth.accounts.privateKeyToAccount(PRIVATE_KEY);
  
  return (tokenAddress: string, decimals: number) => {
    const contract = new web3.eth.Contract(abi, tokenAddress);

    const multiplier = new BigNumber(10).pow(decimals);

    return async (address: string, amount: string) => {
      const bn = new BigNumber(amount).multipliedBy(multiplier);

      const method = contract.methods.mint(address, bn.toFixed(0));

      const gas = await method.estimateGas({from: signer.address});
      const gasPrice = await web3.eth.getGasPrice();
      const data = method.encodeABI();
      const nonce = await web3.eth.getTransactionCount(signer.address);

      console.log({
        to: tokenAddress, 
        data,
        gas: gas,
        gasPrice,
        nonce, 
        chainId: networkId
      });
    
      const signedTx = await web3.eth.accounts.signTransaction(
        {
          to: tokenAddress, 
          data,
          gas: gas.toString(),
          gasPrice,
          nonce, 
          chainId: networkId
        },
        PRIVATE_KEY
      );
    
      const receipt = await web3.eth.sendSignedTransaction(signedTx.rawTransaction);

      return receipt.transactionHash.toString();
    }
  }
}

// Chain Ids - https://chainlist.org/chain/11155111?testnets=true

// SEPOLIA
const ethSepoliaWeb3 = setupWeb3('https://sepolia.infura.io/v3/741fd010ea5848349d2d005114c659e1', 11155111);
// Fireblocks - USDC_B75VRLGX_GQ97
export const ethSepoliaUSDC = ethSepoliaWeb3('0xDe9f87ca1C86a12D8Ef0FD823797daC638d7A617', 6);
// Fireblocks - USDC_B6BMTT9T_IUJV
export const ethSepoliaMATIC = ethSepoliaWeb3('0x7Db79A6bf14EfC66fFd5d729631D65bb46B3EFE8', 18);
// Fireblocks - USDC_B6BMTT9T_T9YG
export const ethSepoliaPOL = ethSepoliaWeb3('0x3202462C50605fcC39AAd5B0b39A2Fb436C117c5', 18);

// POLYGON AMOY
const polygonAmoyWeb3 = setupWeb3('https://polygon-amoy.infura.io/v3/741fd010ea5848349d2d005114c659e1', 80002);
// Fireblocks - USDC_B6BMTT9T_IUJV
export const polygonAmoyUSDCOne = polygonAmoyWeb3('0xF518817CfD4872DAbfafab800487eF8Ee54d6D3b', 6);
// Fireblocks - USDC_B6BMTT9T_T9YG
export const polygonAmoyUSDCTwo = polygonAmoyWeb3('0xAEEBc2b40B7E8F9083B946241F0196700E551673', 6);

// BSC 
// const bscTestnetWeb3 = setupWeb3('https://bsc-testnet.infura.io/v3/741fd010ea5848349d2d005114c659e1', 97);

