import { Card } from "react-bootstrap";

export default function AVAX() {
  return (
    <div>
      <Card>
        <Card.Body>
          <Card.Title>AVAX</Card.Title>
          <Card.Text>AVAX C Chain</Card.Text>

          <div>
            <Card.Text>
              This Faucet requires some real mainnet AVAX in your account <a href="https://core.app/tools/testnet-faucet/?subnet=c&token=c">Faucet</a>
            </Card.Text>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
}
