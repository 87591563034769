import { useState } from 'react'

interface Props {
  currency: string,
  chain: string,
  maxAmount: number,
  mintHandler: (address: string, amount: string) => Promise<string>
}

const MintToken = ({ currency, mintHandler, chain, maxAmount }: Props) => {
  const [address, setAddress] = useState('');
  const [amount, setAmount] = useState('');
  const [hash, setHash] = useState('');
  const [loading, setLoading] = useState(false);

  const handleMint = async () => {
    if (+amount > maxAmount) {
      alert(`Amount should be less than ${maxAmount}`);
      return;
    }

    setLoading(true);
    setHash('');

    try {
      const txnHash = await mintHandler(address, amount);

      setHash(txnHash);
    } catch(e: any) {
      alert(`Error: ${e.message}`);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div style={{ paddingTop: '16px'}}>
      <h5>Mint {currency} ({chain})</h5>

      <input type="text" placeholder="Address" onChange={(e) => setAddress(e.target.value)} />
      <input type="text" placeholder="Amount" onChange={(e) => setAmount(e.target.value)} />
      <button onClick={handleMint} disabled={loading}>Mint</button>
      {
        hash && <div>Successful Txn, Hash - {hash}</div>
      }
      {
        loading&& <div>loading...</div>
      }
    </div>
  )
}

export default MintToken