import { Button, Card } from "react-bootstrap";

export default function DOGE() {
  return (
    <div>
      <Card>
        <Card.Body>
          <Card.Title>DOGE</Card.Title>

          <Button variant="link">
            <a href="https://testnet-faucet.com/doge-testnet/">DOGE Faucet</a>
          </Button>
        </Card.Body>
      </Card>
    </div>
  );
}
