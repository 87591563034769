import { Button, Card, Form } from "react-bootstrap";
import { Connection, PublicKey } from "@solana/web3.js";
import { useState } from "react";

export default function SOL() {
  const [address, setAddress] = useState("");

  const requestAirdrop = async () => {
    if (!address) {
      alert("You need to enter the address");
    } else {
      // connection
      const connection = new Connection("https://api.devnet.solana.com");

      // 5YNmS1R9nNSCDzb5a7mMJ1dwK9uHeAAF4CmPEwKgVWr8
      const receiver = new PublicKey(address);

      try {
        await connection.requestAirdrop(receiver, 1e9);

        alert("1 SOL Received");
      } catch (e: any) {
        alert("Error: " + e.message);
      }
    }
  };

  return (
    <div>
      <Card>
        <Card.Body>
          <Card.Title>SOL</Card.Title>
          <Card.Text>
            For Sol, you may use the below form to request 1 SOL at a time.
            Beware there is a rate limit. You do not need to be connected to a
            wallet.
          </Card.Text>
          <Form>
            <Form.Label>Sol Address</Form.Label>
            <Form.Control
              value={address}
              onChange={(v) => setAddress(v.target.value)}
              type="text"
              autoComplete="off"
              id="solAddress"
              placeholder="Sol Address"
            />
            <Button style={{ marginTop: "16px" }} onClick={requestAirdrop}>
              Collect Sol
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
}
