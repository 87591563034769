import { Card } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { ethSepoliaUSDC, polygonAmoyUSDCOne, polygonAmoyUSDCTwo } from '../../utils/token-claim';
import MintToken from '../MintToken/MintToken';

export default function USDC() {
  return <div>
    <Card>
      <Card.Body>
        <Card.Title>USDC</Card.Title>
        <Card.Text>
          <Tabs
            defaultActiveKey="ethereum"
            id="usdc"
            className="mb-3"
          >
            <Tab eventKey="ethereum" title="ETHEREUM CHAIN">
              <MintToken currency="USDC" chain="ETHEREUM" mintHandler={ethSepoliaUSDC} maxAmount={1000000} />
            </Tab>
            <Tab eventKey="bsc" title="BNB Smart Chain">
              Nothing for now
            </Tab>
            <Tab eventKey="polygon" title="POLYGON">
              <MintToken currency="USDC_e" chain="MATIC" mintHandler={polygonAmoyUSDCOne} maxAmount={1000000} />
              <MintToken currency="USDC" chain="MATIC" mintHandler={polygonAmoyUSDCTwo} maxAmount={1000000} />
            </Tab>
          </Tabs>
        </Card.Text>
      </Card.Body>
    </Card>
  </div>
}