import { Card } from 'react-bootstrap';

export default function MATIC() {
  return <div>
    <Card>
      <Card.Body>
        <Card.Title>TRX</Card.Title>
        <Card.Text>
          <div>Follow the below instructions</div>
          <ol>
            <li>Join the Tron <a href="https://discordapp.com/invite/hqKvyAM">Discord</a>. </li>
            <li>Go to the #Faucet channel</li>
            <li>Type !shasta [Replace With Your Address]</li>
          </ol>
        </Card.Text>
      </Card.Body>
    </Card>
  </div>
}