import React from 'react';
import { Card, Form, Button, ListGroup } from 'react-bootstrap';
import BigNumber from 'bignumber.js';
import * as xrpl from 'xrpl';

function get(amount: string, address: string, tag: string) {
  return fetch('https://faucet.altnet.rippletest.net/accounts', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    }
  }).then((data) => {
    return data.json();
  }).then(async (data) => {
    const client = new xrpl.Client("wss://s.altnet.rippletest.net:51233")
    await client.connect()

    const wallet = xrpl.Wallet.fromSeed(data.seed);

    const transaction: xrpl.SubmittableTransaction = {
      TransactionType: 'Payment',
      Account: wallet.classicAddress,
      DestinationTag: tag ? Number(tag) : undefined,
      Destination: address,
      Amount: amount // 10 XRP in drops (1/1,000,000th of an XRP)
    }
    const submitResponse = await client.submit(transaction, { wallet })

    return submitResponse;
  })
}

export default function XRP() {
  const [address, setAddress] = React.useState('');
  const [tag, setTag] = React.useState('');
  const [amount, setAmount] = React.useState('888');
  const [completedTxns, setCompletedTxns] = React.useState<{ amount: string, url: string }[]>([])

  const onSubmit = async () => {
    try {
      const res = await get(new BigNumber(amount).multipliedBy(10 ** 6).toString(), address, tag);

      const hash = res.result.tx_json.hash;

      setCompletedTxns([...completedTxns, {
        amount,
        url: `https://testnet.xrpl.org/transactions/${hash}`
      }])
    } catch (e) {
      alert('Error sending XRP: ' + (e as Error).message)
    }
  }

  return <div>
    <Card>
      <Card.Body>
        <Card.Title>XRP</Card.Title>
        <Card.Text>
          <Form>
            <Form.Group controlId="address">
              <Form.Label>Address</Form.Label>
              <Form.Control type="string" placeholder="Address" onChange={(e) => setAddress(e.target.value)} value={address} />
            </Form.Group>

            <Form.Group controlId="tag">
              <Form.Label>Destination Tag</Form.Label>
              <Form.Control type="string" placeholder="Desintation Tag" onChange={(e) => setTag(e.target.value)} value={tag} />
            </Form.Group>

            <Form.Group controlId="amount">
              <Form.Label>Amount</Form.Label>
              <Form.Control type="string" placeholder="Amount" onChange={(e) => setAmount(e.target.value)} value={amount} />
            </Form.Group>

            <Button variant="primary" onClick={onSubmit} type="button">
              Submit
            </Button>
          </Form>
        
          <div>
            <span>Completed Transactions</span>
            <ListGroup>
              {completedTxns.map((txn, i) => (
                <ListGroup.Item key={txn.url}><a href={txn.url} target="_blank">{txn.amount}</a></ListGroup.Item>
              ))}
            </ListGroup>
          </div>
          <div>
            if the above is not working, use either 
            <a href="https://yusufsahinhamza.github.io/xrp-testnet-faucet/" target="_blank">Faucet 1</a>
            <a href="https://test.xrplexplorer.com/en/faucet?address=rGqDvSVtN7Rq3QBdmZ6wriGBPJCFjWv7af&amount=50" target="_blank">Faucet 2</a>
          </div>
        </Card.Text>
      </Card.Body>
    </Card>
  </div>
}