import { Card } from 'react-bootstrap';

export default function LTC() {
  return <div>
    <Card>
      <Card.Body>
        <Card.Title>LTC</Card.Title>
        <Card.Text>
          Please use this LTC Testnet<a href="https://litecointf.salmen.website/">Faucet</a>
        </Card.Text>
      </Card.Body>
    </Card>
  </div>
}