import Container from 'react-bootstrap/Container';
import './App.css';
import Main from './components/Main';

function App() {
  return (
    <Container className="App">
      <h2>Shuffle Testnet Faucets</h2> 
      <Main />
    </Container>
  );
}

export default App;
