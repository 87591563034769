import { Card } from 'react-bootstrap';

export default function USDT() {
  return <div>
    <Card>
      <Card.Body>
        <Card.Title>USDT</Card.Title>
        <Card.Text>
          Not available for testnet
        </Card.Text>
      </Card.Body>
    </Card>
  </div>
}