import { Button, Card } from "react-bootstrap";

export default function ETH() {
  return <div>
    <Card>
      <Card.Body>
        <Card.Title>ETH</Card.Title>
        <Card.Text>
          For ETH, we are using the Goerli network, you may use the below faucet. Requires login
        </Card.Text>

        
        <Button variant="link">
          <a href="https://www.alchemy.com/faucets/ethereum-sepolia">ETH Faucet (Requires Google Login)</a>
        </Button>

        <Button variant="link">
          <a href="https://sepolia-faucet.pk910.de/">POW ETH Faucet</a>
        </Button>
        
      </Card.Body>
    </Card>
  </div>
}