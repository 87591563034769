import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import 'bootstrap/dist/css/bootstrap.min.css';
import BTC from './BTC/BTC';
import ETH from './ETH/ETH';
import SOL from './SOL/SOL';
import USDC from './USDC/USDC';
import MATIC from './MATIC/MATIC';
import LTC from './LTC/LTC';
import TRX from './TRX/TRX';
import USDT from './USDT/USDT';
import BUSD from './BUSD/BUSD';
import XRP from './XRP/XRP';
import DOGE from './DOGE/DOGE';
import BNB from './BNB/BNB';
import AVAX from './AVAX/AVAX';

function Main() {
  return (
    <Tabs
      defaultActiveKey="btc"
      id="uncontrolled-tab-example"
      className="mb-3"
    >
      <Tab eventKey="btc" title="BTC">
        <BTC />
      </Tab>
      <Tab eventKey="eth" title="ETH">
        <ETH />
      </Tab>
      <Tab eventKey="ltc" title="LTC">
        <LTC />
      </Tab>
      <Tab eventKey="trx" title="TRX">
        <TRX />
      </Tab>
      <Tab eventKey="matic" title="MATIC">
        <MATIC />
      </Tab>
      <Tab eventKey="usdc" title="USDC">
        <USDC />
      </Tab>
      <Tab eventKey="usdt" title="USDT">
        <USDT />
      </Tab>
      <Tab eventKey="sol" title="SOL">
        <SOL />
      </Tab>
      <Tab eventKey="busd" title="BUSD">
        <BUSD />
      </Tab>
      <Tab eventKey="xrp" title="XRP">
        <XRP />
      </Tab>
      <Tab eventKey="doge" title="DOGE">
        <DOGE />
      </Tab>
      <Tab eventKey="bnb" title="BNB">
        <BNB />
      </Tab>
      <Tab eventKey="avax" title="AVAX">
        <AVAX />
      </Tab>
    </Tabs>
  );
}

export default Main;