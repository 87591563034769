import { Button, Card } from "react-bootstrap";

export default function BNB() {
  return (
    <div>
      <Card>
        <Card.Body>
          <Card.Title>BNB</Card.Title>
          <Card.Text>Binance Smert Chain</Card.Text>

          <Button variant="link">
            <a href="https://testnet.bnbchain.org/faucet-smart">BNB Faucet</a>
          </Button>
        </Card.Body>
      </Card>
    </div>
  );
}
