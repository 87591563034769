import { Button, Card } from "react-bootstrap";

export default function BTC() {
  return <div>
    <Card>
      <Card.Body>
        <Card.Title>BTC</Card.Title>
        <Card.Text>
          For BTC, you may use one of the below faucet
        </Card.Text>
        <Button variant="link">
          <a href="https://bitcoinfaucet.uo1.net/">BTC Faucet 1</a>
        </Button>
        <Button variant="link">
          <a href="https://testnet-faucet.com/btc-testnet/">BTC Faucet 2</a>
        </Button>
      </Card.Body>
    </Card>
  </div>
}